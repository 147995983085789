<template>
    <div>
        <img :src="backgroundImage" style="position: absolute; top: 130px; height: min-content; width: min-content;"/>

        <div v-for="(number, index) in availableNumbers"
             :key="index"

             :style="getNumberPosition(index)"
             style="position: absolute"
        >

            <template v-if="isNumberMatched(number)">
                <img :src="winNumberImage"/>
                <span class="text text__yellow">{{ number }}</span>
            </template>
            <template v-else-if="isNumberSelected(number)">
                <img :src="orangeNumberImage"/>
                <span class="text text__orange">{{ number }}</span>
            </template>
            <template v-else-if="isNumberResult(number)">
                <img :src="blueNumberImage"/>
                <span class="text text__blue">{{ number }}</span>
            </template>
            <template v-else>
                <img :src="greenNumberImage"/>
                <span class="text text__green">{{ number }}</span>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        resultNumbers: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        ticketNumbers: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        matchNumbers: {
            type: Array,
            required: true,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            start: {
                top: 228.4,
                left: 178
            },
            number: {
                width: 63,
                height: 62
            },
            margin: {
                left: 4.2,
                top: 3.5
            },
        }
    },
    computed: {
        backgroundImage() {
            return require('@/assets/games/keno/egypt-keno-spain/table_bg.png');
        },
        availableNumbers() {
            return [...Array(80).keys()].map(i => i + 1);
        },
        blueNumberImage() {
            return require('@/assets/games/keno/egypt-keno-spain/blocks/num_blue_backgr.png');
        },
        greenNumberImage() {
            return require('@/assets/games/keno/egypt-keno-spain/blocks/num_green_backgr.png');
        },
        orangeNumberImage() {
            return require('@/assets/games/keno/egypt-keno-spain/blocks/num_orange_backgr.png');
        },
        winNumberImage() {
            return require('@/assets/games/keno/egypt-keno-spain/blocks/num_win_backgr.png');
        },
    },
    methods: {
        getNumberPosition(index) {
            let one = index % 10;
            let dozen = Math.floor(index / 10);

            let left = this.start.left + (this.margin.left + this.number.width) * one;
            let top = this.start.top + (this.margin.top + this.number.height) * dozen;

            return {
                left: `${left}px`,
                top: `${top}px`
            };
        },
        isNumberMatched(number) {
            return this.matchNumbers.includes(number);
        },
        isNumberSelected(number) {
            return this.ticketNumbers.includes(number);
        },
        isNumberResult(number) {
            return this.resultNumbers.includes(number);
        }
    }
}
</script>

<style scoped>
.text {
    font-weight: bold;
    font-size: 32px;
    cursor: default;
    white-space: nowrap;

    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.text__orange {
    color: #F3AB02;
}

.text__green {
    color: #92954e;
}

.text__blue {
    color: #25305D;
}

.text__yellow {
    color: #E6DE00;
}

.result__img {
    position: absolute;
}
</style>
