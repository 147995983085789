<template>
    <div class="background">
        <egypt-keno-numbers :match-numbers="stateData.roundResult.result.matchNumbers"
                            :result-numbers="stateData.roundResult.resultNumbers"
                            :ticket-numbers="stateData.roundResult.numbers"

                            class="numbers"
        />

        <div>
            <egypt-keno-result :result-numbers="stateData.roundResult.resultNumbers"

                               class="result"
            />
        </div>
    </div>
</template>

<script>
import EgyptKenoNumbers from "./EgyptKenoNumbers";
import EgyptKenoResult from "./EgyptKenoResult";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    roundResult: {
                        numbers: null,
                        resultNumbers: null,
                        result: {
                            matchCount: null,
                            matchNumbers: null,
                            win: null
                        }
                    },
                    denom: null
                }
            }
        }
    },
    components: {
        'egypt-keno-numbers': EgyptKenoNumbers,
        'egypt-keno-result': EgyptKenoResult
    },
    mounted() {
        this.$store.dispatch('workspace/setHeight', 925);
        this.$store.dispatch('workspace/setWidth', 1343);
    }
}
</script>

<style scoped>
.background {
    background-image: url('../../../../../assets/games/keno/egypt-keno-spain/bg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 1343px;
    height: 925px;
}

.numbers {
    position: relative;
    top: 4px;
}

.result {
    position: relative;
    top: 280px;
    left: 950px;
}
</style>
